.PhoneInput input {
    border-radius: 8px;
    border: 1px solid var(--inputBorderColor);
    background-color: var(--inputBackgroundColor);
    color: var(--inputTextColor);
    height: 2.7rem;
    font-size: 14px;
}

.PhoneInput .PhoneInputCountrySelect {
    background-color: var(--react-country-select);
    color: var(--inputTextColor);
}
