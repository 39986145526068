#Polygon-1,
#Polygon-2,
#Polygon-3,
#Polygon-4,
#Polygon-5 {
    animation: float 1s infinite ease-in-out alternate;
}

#Polygon-2 {
    animation-delay: 0.2s;
}

#Polygon-3 {
    animation-delay: 0.4s;
}

#Polygon-4 {
    animation-delay: 0.6s;
}

#Polygon-5 {
    animation-delay: 0.8s;
}

@keyframes float {
    100% {
        transform: translateY(50px) translateX(20px);
    }
}
