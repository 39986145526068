/* tailwind css */
@tailwind base;

@layer base {
    .h0 {
        @apply text-3xl font-bold md:text-5xl text-[#505050] dark:text-white;
    }

    h1,
    .h1 {
        @apply text-2xl font-bold md:text-4xl text-[#505050] dark:text-white;
    }

    h2,
    .h2 {
        @apply text-xl font-bold md:text-3xl text-[#505050] dark:text-white;
    }

    h3,
    .h3 {
        @apply text-lg font-bold md:text-2xl text-[#505050] dark:text-white;
    }

    h4,
    .h4 {
        @apply text-base font-bold md:text-lg text-[#505050] dark:text-white;
    }
    h5,
    .h5 {
        @apply text-sm font-bold  text-[#505050] dark:text-white;
    }

    body,
    .p {
        @apply text-sm  text-[#505050] dark:text-white;
    }
}

@tailwind components;
@tailwind utilities;

:root {
    --light-background: #ffff;
    --dark-background: #0d1117;
    --dark-tertiaryBgBtn: #3e4857;
    --light-tertiaryBgBtn: #eef3ff;
    --secondary-background-light: #f9f9fb;
    --secondary-background-dark: #0d1117;
    --logo-color: #000246;
    --default-dark-bg-color: #374151;
    --inputBorderColorLight: #d1d5db;
    --inputBorderColorDark: #505050;
    --inputTextColorLight: 'black';
    --inputTextColorDark: 'white';
    --inputTextColorLight: 'black';
    --inputTextColorDark: 'white';
    --inputBackgroundColorDark: #374151;
    --inputBackgroundColorLight: #f9f9fb;
    --react-country-selectLight: #eaedf7;
    --react-country-selectDark: #191c24;
}

html {
    --background: var(--light-background);
    --tertiaryBgBtn: var(--light-tertiaryBgBtn);
    --secondaryBackground: var(--secondary-background-light);
    --logoColor: var(--logo-color);
    --layoutBgColor: var(--light-background);
    --scrollbar-thumb: rgba(0, 0, 0, 0.1);
    --scrollbar-track: rgba(0, 0, 0, 0.2);
    --scrollbar-thumb-hover: rgba(0, 0, 0, 0.3);
    --scrollbar-thumb-active: rgba(0, 0, 0, 0.4);
    --inputBorderColor: var(--inputBorderColorLight);
    --inputTextColor: var(--inputTextColorLight);
    --inputBackgroundColor: var(--inputBackgroundColorLight);
    --react-country-select: var(--react-country-selectLight);
}

html.dark {
    --background: var(--dark-background);
    --tertiaryBgBtn: var(--dark-tertiaryBgBtn);
    --secondaryBackground: var(--secondary-background-dark);
    --logoColor: var(--light-background);
    --layoutBgColor: var(--default-dark-bg-color);
    --tejba: var(--tejrabaokhra);
    --scrollbar-thumb: #666b7a;
    --scrollbar-track: #999b9e;
    --scrollbar-thumb-hover: #5b5f6b;
    --scrollbar-thumb-active: #5e626e;
    --inputBorderColor: var(--inputBorderColorDark);
    --inputTextColor: var(--inputTextColorDark);
    --inputBackgroundColor: var(--inputBackgroundColorDark);
    --react-country-select: var(--react-country-selectDark);
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--scrollbar-thumb);
}
::-webkit-scrollbar-track {
    border-radius: 10px;
    background: var(--scrollbar-track);
}
::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover);
}
::-webkit-scrollbar-thumb:active {
    background: var(--scrollbar-thumb-active);
}
body {
    background-color: var(--background);
    overflow-x: hidden;
}

/* Date picker styling */
:root {
    --rdp-accent-color: #578aff;
}
.rdp-day_today:not(.rdp-day_outside) {
    font-weight: bold;
    color: #578aff;
}
.rdp-day_selected:not([aria-disabled='true']),
.rdp-day_selected:focus:not([aria-disabled='true']),
.rdp-day_selected:active:not([aria-disabled='true']),
.rdp-day_selected:hover:not([aria-disabled='true']) {
    color: white !important;
    background-color: #578aff;
}
