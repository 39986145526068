.flag-icon-background {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}
.flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
}
.flag-icon:before {
    content: '\00a0';
}
.flag-icon.flag-icon-squared {
    width: 1em;
}
.flag-icon-ad {
    background-image: url(../../assets/flags/ad.svg);
}

.flag-icon-cad {
    background-image: url(../../assets/flags/ca.svg);
}

.flag-icon-eur {
    background-image: url(../../assets/flags/eu.svg);
}
.flag-icon-usd {
    background-image: url(../../assets/flags/us.svg);
}

.flag-icon-xx {
    background-image: url(../../assets/flags/xx.svg);
}

/* .flag-icon-ae {
    background-image: url(../../assets/flags/ae.svg);
} */
